const CampaignDashboardPage = () => import('@/pages/dashboard/CampaignDashboardPage.vue')
const SoundDashboardPage = () => import('@/pages/dashboard/SoundDashboardPage.vue')

export default [
  {
    path: 'campaign/:brandCampaignId',
    name: 'campaignDashboard',
    component: CampaignDashboardPage,
    meta: {
      authIndependent: true,
      noSideItems: true,
    },
  },
  {
    path: 'campaign-group/:brandCampaignGroupId',
    name: 'campaignGroupDashboard',
    component: CampaignDashboardPage,
    meta: {
      authIndependent: true,
      noSideItems: true,
    },
  },
  {
    path: 'tiktok-sound/:tiktokSoundId',
    name: 'soundDashboard',
    component: SoundDashboardPage,
    meta: {
      authIndependent: true,
      noSideItems: true,
    },
  },
]
